import { useEffect, useState, useRef } from 'react'
import Link from 'next/link'

interface FeaturedOfferingProps {
	heading?: string
	offering?: any
}

const FeaturedOffering = (props: FeaturedOfferingProps) => {
	const { heading, offering } = props

	const [ offeringsList, setOfferingsList ] = useState<any[]>([]);
	const [ counter, setCounter ] = useState(3);
	const [ degree, setDegree ] = useState(0);
	const [ activeOffering, setActiveOffering ] = useState<any>(null);

	const cubeRef = useRef<HTMLDivElement>(null);
	const linkRef = useRef<HTMLAnchorElement>(null);

	useEffect(() => {
		if (offering) {
			if (offering.length <= 3) {
				setOfferingsList(offering.concat(offering));
			} else {
				setOfferingsList(offering);
			}
		}
	}, [offering]);

	const setOffering = () => {
		// console.log('interval counter', counter)
		// console.log(offeringsList, offeringsList[counter])

		const cubeNode = cubeRef.current;
		const linkNode = linkRef.current;

		let numberOfLinks = offering.length - 1;

		if (offering.length <= 3) {
			numberOfLinks = 3;
		}

		if (offeringsList.length && cubeNode && linkNode) {

			if (offeringsList[counter]) {
				if (counter == 0) {
					setCounter(numberOfLinks);
				} else {
					setCounter(counter - 1);
				}

				setDegree(degree + 90);

				let items = cubeNode.children;

				let offeringLabel = offeringsList[counter].subheading ? `${offeringsList[counter].title} – ${offeringsList[counter].subheading} →` : `${offeringsList[counter].title} →`;
				// let offeringLabel = `${offeringsList[counter].title} - ${offeringsList[counter].uri}`;

				items[counter].innerHTML = offeringLabel;
				cubeNode.style.transform = `rotateX(${degree}deg)`;

				let nextOffering = counter < numberOfLinks ? offeringsList[counter + 1] : offeringsList[0];
				setActiveOffering(nextOffering)

			}

			
		}
	}

	useEffect(() => {
		const cubeNode = cubeRef.current;
		const linkNode = linkRef.current;

		if (offeringsList.length && cubeNode && linkNode) {
			let items = cubeNode.children;

			let offeringLabel = offeringsList[0].subheading ? `${offeringsList[0].title} – ${offeringsList[0].subheading} →` : `${offeringsList[0].title} →`;
			// let offeringLabel = `${offeringsList[0].title} - ${offeringsList[0].uri}`;

			items[0].innerHTML = offeringLabel;
			linkNode.href =`${offeringsList[0].uri}`;			
		}
	}, [offeringsList]);



	useEffect(() => {
		if (offeringsList.length) {
	  		const interval = setInterval(setOffering, 3000);
	  		return () => clearInterval(interval);
	  	}
	}, [counter, offeringsList]);



  	useEffect(() => {
		const cubeNode = cubeRef.current;
		const linkNode = linkRef.current;

    	if (cubeNode && linkNode) {

   			const cubeTransitionDone = (() => {
				if (activeOffering) {
					linkNode.href =`${activeOffering.uri}`;
				}

				cubeNode.removeEventListener("transitionend", cubeTransitionDone);
			});

			cubeNode.addEventListener("transitionend", cubeTransitionDone, false);
		}
  	});

	if ( !offering || offering.length === 0 ) {
		return null
	}

	let offeringsMarkup = null;
	let wrapperClass = '';

	if (offering.length > 1) {
		wrapperClass = 'ticker';

		offeringsMarkup = <a href="" className="featured-offering--link-wrapper" ref={linkRef}>
			<div className="cube" ref={cubeRef}>
			    <span className="featured-offering__link featured-offering__link--front"></span>
			    <span className="featured-offering__link featured-offering__link--top"></span>
			    <span className="featured-offering__link featured-offering__link--back"></span>
			    <span className="featured-offering__link featured-offering__link--bottom"></span>
			</div>
		</a>
	} else if (offering[0]) {
		const offeringHeading = heading ? `${heading} →` : offering[0].subheading ? `${offering[0].title} – ${offering[0].subheading} →` : `${offering[0].title} →`;
		offeringsMarkup = <Link href={`/${offering[0].uri}`} className='featured-offering__link'>{ offeringHeading }</Link>
	}

	return (
		offeringsMarkup && <section className={`featured-offering ${wrapperClass}`}>
			{offeringsMarkup}
		</section>
	)

}
export default FeaturedOffering
