import { getAppData } from '../lib/api/common'
import { getHomePage } from '../lib/api/singles'

import { getFeaturedNews } from '../lib/api/news'
import { getFeaturedDealsBlockItems } from '../lib/api/deals'

import Layout from "../components/layout/Layout"
import SeoMaticHead from '../components/common/SeoMaticHead'
import PageHeader from '../components/content/page/PageHeader'
import PageBuilder from '../components/content/PageBuilder'
import FeaturedOffering from '../components/content/FeaturedOffering'
import CampaignTakeover from '../components/hero/CampaignTakeover'

import {
	revalidateSeconds
} from "../lib/helpers/revalidateSeconds";


export type HomeProps = {
	page: any
	app: any
	dynamicData: any
}


export async function getStaticProps(context: any) {

	const app: any = await getAppData();
	const home: any = await getHomePage(
		context.preview ? context.previewData?.token : undefined
	);

	// Check for dynamic content blocks
	const latestNewsBlocks = home.page?.pageBuilder?.filter((block: any) => block.typeHandle === 'latestNews')
	const featuredDealsBlocks = home.page?.pageBuilder?.filter((block: any) => block.typeHandle === 'featuredDeals')

	// Get latest news if block is present
	const latestNews = latestNewsBlocks && latestNewsBlocks.length > 0 ? await getFeaturedNews('*') : null

	// Get latest featured deals if block is present and no deals are selected
	const getLatestDeals = featuredDealsBlocks?.length > 0
	const featuredDeals = getLatestDeals ? await getFeaturedDealsBlockItems() : null

	return {
		props: {
			app: app ? app : null,
			page: home ? home.page : null,
			dynamicData: {
				news: latestNews?.news ? latestNews.news : null,
				featuredDeals: featuredDeals?.deals ? featuredDeals.deals : null
			}
		},
		revalidate: revalidateSeconds()
	}
}

export default function Home(props: HomeProps) {

	const { page, app, dynamicData } = props

	// console.log('Home page props: ', page);

	return (
		<>
			<SeoMaticHead seo={page.seo} />
			<Layout appData={app} theme={'home'}>
				<>
					{(page.showCampaignTakeover === true) &&
						<CampaignTakeover 
							title={page.subheading} 
							url={page.linkToPage && page.linkToPage[0] ? page.linkToPage[0].uri : null} 
							linkLabel={page.linkLabel} 
							image={page.image && page.image[0] ? page.image[0].url : null}
						/>
					}
					<PageHeader page={page} />
					<FeaturedOffering offering={page.featuredOffering} heading={page.heading} />
					<PageBuilder
						contentBlocks={page.pageBuilder}
						dynamicData={dynamicData}
					/>
				</>
			</Layout>
		</>
	)
}

