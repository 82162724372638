import { useState, useEffect } from 'react'
import Link from 'next/link'
import ResponsiveImage from '../common/ResponsiveImage'
import { CloseIcon } from '../svgs/Icons'
import { Logo } from '../svgs/logo'

interface CampaignTakeoverProps {
	title: string
	url: string
	linkLabel: string
	image: string
}

const CampaignTakeover = (props: CampaignTakeoverProps) => {
	const { title, url, linkLabel, image } = props

	const [ modalOpen, setModalOpen ] = useState(true);

	const closeModal = () => {
		setModalOpen(false);
	}

	if (!modalOpen) {
		return null;
	}

	return (
		<div className={`campaign-takeover`} style={{ backgroundImage: `url(${image})` }}>
			<div className={`campaign-takeover__branding`}>
				<Link href="/" aria-label="Go to home page" className="{`campaign-takeover__logo`}"><Logo /></Link>
			</div>

			<button className="campaign-takeover__close" onClick={closeModal}><CloseIcon /></button>
			<h2>{ title }</h2>

			{url &&
				<Link href={`/${url}`} className="button button--standard">{ linkLabel ?? 'Go to page'}</Link>
			}
		</div>
	)

}
export default CampaignTakeover
