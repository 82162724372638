import Head from 'next/head'
import parse from 'html-react-parser'

interface SeoMaticData {
	metaTagContainer: string,
	metaTitleContainer: string,
	metaSiteVarsContainer: string,
	metaLinkContainer: string,
	metaJsonLdContainer: string
}

interface SeoMaticHeadProps {
	seo: SeoMaticData
}

const SeoMaticHead = ({ seo }: SeoMaticHeadProps) => {

	if ( !seo ) {
		return null
	}

	return (
		<Head>
			<link rel="icon" href="/favicon.svg" type="image/svg+xml" />
			<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
			<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
			<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
			<link rel="manifest" href="/site.webmanifest" />
			<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
			<meta name="msapplication-TileColor" content="#da532c" />
			<meta name="theme-color" content="#ffffff" />
			{parse(seo.metaTitleContainer)}
			{parse(seo.metaTagContainer)}
			{parse(seo.metaSiteVarsContainer)}
			{parse(seo.metaLinkContainer)}
			{parse(seo.metaJsonLdContainer)}
		</Head>
	)

}

export default SeoMaticHead
